@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .animate-fade-in {
    animation: fadeIn 0.5s ease-in-out forwards;
    animation-delay: 3s;
  }
  
  .animate-fade-out {
    animation: fadeOut 0.5s ease-in-out forwards;
  }